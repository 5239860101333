.hidden {
    display: none;
  }

  html {
    overflow-y: scroll;
  }

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid grey;
}

.logo-container {
  margin-right: 10px; /* Adjust margin as needed */
}

.logo-image {
  width: 250px;
  height: auto;
}

.navigation-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.profile-dropdown li p{
  margin-left:0px !important;
  text-align:center;
  border-width:0px;
  border-top-style:solid;
  border-top-width:1px;
  font-size:15px;
  padding-top:8px;
  min-height:22px;
  margin-bottom:0px;
 }

 /* List Item */
 .navigation-links li li{
  width:188px;
  text-align:center;
  padding-top:0px;
 }

 /* List */
 .navigation-links li ul{
  transform:translatex(-107px) translatey(27px);
 }


.navigation-links li {
  margin-right: 10px; /* Adjust margin as needed */
}

.navigation-links li:last-child {
  margin-right: 0;
}

.create-group-button {
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 900;
}

.login {
  background-color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.signup {
  background-color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.profile-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
}

.profile-icon {
  color: #FF5A5F;
  font-size: 30px;
  transition: transform 0.1s ease;
}

.arrow-icon {
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  color: grey;
  font-size: 19px;
  transition: transform 0.2s ease;
}

.active .arrow-icon {
  transform: translateY(-50%) rotate(180deg);
}

.navigation-links li .fa-chevron-up{
  padding-right:3px;
  margin-left:0px;
  width:29px;
 }

 .navigation-links li .fa-user-circle{
  padding-right:24px;
 }


 /* PROFILE DROPDOWN IN NAV BAR */
 .profile-dropdown {
  position: absolute;
  background-color: white;
  border: solid black 1px;
  padding-left: 0px;
  list-style-type: none;
 }

 /* List */
.navigation-links li ul{
  transform:translatex(-107px) translatey(11px);
  width:189px;
 }

 /* Menu logout */
 .profile-dropdown li .MenuLogout{
  padding-right:9px;
  margin-left:63px;
  margin-top:17px;
 }

.profile-dropdown {
  font-weight: 600;
}

 /* LOGOUT BUTTON IN DROPDOWN */
 .MenuLogout {
  cursor: pointer;
  border-top: 2px;
  font-weight: 600;
 }
