body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
}

html {
  overflow-y: scroll;
}

.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navigation-links li .login,
.navigation-links li .signup {
  font-size: 15px;
  font-weight: 400;
}

.navigation-links li .fa-chevron-up {
  margin-left: 0;
  width: 44px;
}

.navigation-links li .fa-user-circle {
  margin-left: 0;
  width: 58px;
}

.landingPage .features-section .feature1 {
  padding-left: 213px;
}

.landingPage .features-section .feature3 {
  padding-right: 213px;
}

.intro {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-left: 90px;
  padding-right: 20px;
  align-items: center;
}

.intro h1 {
  transform: translatex(-5px);
  direction: ltr;
  column-count: 1;
  letter-spacing: 0.7px;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 1px 1px;
  text-align: left;
  padding-left: 7px;
  width: 358px;
  transform: translatex(0px) translatey(0px);
}

.intro p {
  width: 384px !important;
  font-weight: 600;
  transform: translatex(0px) translatey(0px);
}

.intro-content {
  flex: 1;
  max-width: 800px;
  margin-right: 20px;
}

.intro .intro-content p {
  width: 90%;
}

.intro .image-section img {
  width: 347px;
  min-height: 287px;
}

.featurePart .feature-description h3 {
  font-size: 25px;
}

.featurePart .feature-p p {
  font-size: 14px;
  font-style: bold;
}

.features-section {
  display: flex;
  justify-content: center;
}

.feature1,
.feature2,
.feature3 {
  flex: 1;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.feature2 a h3{
  perspective:0px;
  min-height:38px;
  transform:translatex(0px) translatey(8px);
  margin-bottom:0px;
 }

.feature1 h3,
.feature2 h3,
.feature3 h3 {
  color: #00525E;
  cursor: pointer;
}

.feature1 h3:hover,
.feature2 h3:hover,
.feature3 h3:hover {
  text-decoration: underline;
}

.feature1 img,
.feature2 img,
.feature3 img {
  cursor: pointer;
}

.feature3.disabled img {
  cursor: default;
  pointer-events: none;
}

.featurePart {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.join-section {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.join-button {
  transform:translatex(-8px) translatey(-37px);
  padding: 10px 20px;
  font-size: 18px;
  background-color: #00525E;
  color: #ffffff;
  border: none;
  cursor: pointer;
  box-shadow: 5px 2px 2px black;
}

.feature3 .disabled-text {
  color: rgb(157, 157, 157);
  cursor: default;
  pointer-events: none;
}

.feature3.disabled h3 {
  cursor: default;
}


.feature3 .disabled-paragraph {
  color: rgb(0, 0, 0);
  cursor: default;
  pointer-events: none;
}
.disabled-text {
  cursor: default;
  pointer-events: none;
}

.hover-pointer:hover {
  cursor: pointer;
}

#root .landingPage .features-section .feature1 p{
  width:113% !important;
 }


@media (min-width: 1024px) {
  #root .landingPage .features-section .feature2 p {
    width: 106% !important;
  }
}
