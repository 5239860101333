html {
  overflow-y: scroll;
  overflow-x: hidden;
}

.event-detail-container {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.event-detail-breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  transform: translate(16px, -11px);
}

.event-detail-header-container {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.event-detail-body-image {
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  padding-top: 20px;
  margin-top: 40px;
}

.event-detail-body-info-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.event-detail-body-info-event {
  display: flex;
  flex-direction: column;
}

.event-detail-body-info-event-details-start-time {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.event-detail-body-info-event-details-time-container {
  display: block;
}

.START {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
}

.END {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
}

.event-detail-body-info-event-time-details .event-detail-body-info-event-details-start-time .START {
  min-height: 32px;
  transform: translateX(692px) translateY(-271px);
}

/* Span Tag */
.event-detail-body-info-event-time-details .event-detail-body-info-event-details-start-time span {
  transform: translateX(684px) translateY(-268px);
  min-height: 33px;
}

/* Span Tag */
.event-detail-body-info-event-time-details .event-detail-body-info-event-details-end-time span {
  display: inline-block;
  height: 28px;
  transform: translateX(692px) translateY(-278px);
}

/* End */
.event-detail-body-info-event-time-details .event-detail-body-info-event-details-end-time .END {
  width: 165px;
  min-height: 32px;
  transform: translateX(751px) translateY(-311px);
  padding-top: 2px;
}

/* Font Icon */
.event-detail-body-info-event-time-details i {
  font-size: 36px;
  transform: translateX(632px) translateY(-229px);
  color: #c9bfbf;
}

/* Paragraph */
.event-detail-body-info-event .event-detail-body-info-event-price-details p {
  width: 7%;
  font-weight: 500;
  font-size: 17px;
  transform: translateX(694px) translateY(-312px);
  margin-bottom: 0px;
  min-height: 29px;
}

/* Paragraph */
.event-detail-body-info-event .event-detail-body-info-event-type-details p {
  width: 16%;
  font-size: 14px;
  font-weight: 500;
  transform: translateX(632px) translateY(-328px);
  padding-top: 7px;
}

.event-delete-button {
  background-color: #999999;
  color: white;
  box-shadow: 2px 2px black;
  margin-right: 8px;
  cursor: pointer;
  width: 12%;
  min-height: 28px;
  transform: translateX(821px) translateY(-363px);
}

.event-delete-button:hover {
  background-color: #ff5a5f;
  color: white;
}

/* Event detail body description */
#root .event-detail-body-description {
  transform: translateX(36px) translateY(-353px);
}

/* Division */
#root .event-detail-body-info-event .event-detail-body-info-event-type-details {
  font-size: 21px;
}

.event-detail-body-info .event-detail-body-info-group img {
  transform: translateX(-197px) translateY(-279px);
}

.event-detail-body-info .event-detail-body-info-group .event-detail-body-info-group-body {
  transform: translateX(-178px) translateY(-275px);
}

#root .event-detail-header-container h2 {
  transform: translateX(-62px) translateY(33px);
}

#root .event-detail-header-container p {
  transform: translateX(-243px) translateY(73px);
}
