html {
  overflow-y: scroll;
}

.group-form {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
}

.form-container {
  text-align: left;
  margin-bottom: 20px;
}

.form-title {
  font-size: 20px;
  font-weight: bold;
  color: teal;
}

.form-step {
  margin-bottom: 20px;
}

.form-step h3 {
  font-size: 20px;
  font-weight: bold;
}

.form-step span {
  display: block;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 20px;
}

.form-input,
.form-select,
.form-textarea {
  width: 30%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid black;
  background-color: #f4f4f4;
}

.form-textarea {
  height: 100px;
  font-family: "Raleway", sans-serif;
}

.form-error {
  color: red;
  margin-top: 0.25rem;
  font-weight: 900;
  font-style: bold;
}

.form-submit-btn {
  display: block;
  width: 20%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #e32359;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-shadow: 4px 5px 6px -3px #000000;
}

.form-submit-btn:hover {
  background-color: #aa0030;
}

.form-step .form-group input[type=url]{
  width:215px;
 }

