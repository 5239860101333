.delete-group-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 1rem;
    border: 2px solid black;
    border-radius: .5rem;
    max-width: 500px;
    padding: 20px 30px 40px;
    text-align: center;
}

#modal-content .delete-group-modal .delete-group-modal-buttons{
    transform:translatex(0px) translatey(0px);
    width:353px;
   }

.delete-group-modal-title {
    width: 20rem;
    color: rgb(0, 0, 0);
    height: 2.5rem;
    margin-top: 10px auto;
    font-size: 30px;
}


.message {
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 0px;
    margin-bottom: .5rem;
    font-size: 15px;
    font-weight: 600;
}

.delete-group-modal-buttons {
    display: flex;
    flex-direction: column;
    font-size: 13px;
    padding: 10px;
    width: 300px;
}


.delete-group-modal .delete-group-modal-buttons .yes{
    padding-right:0px;
    transform:translatex(0px) translatey(0px);
    text-align:center;
    column-count:1;
   }

.delete-group-modal .delete-group-modal-buttons .no{
    padding-right:0px;
    margin-top:15px;
   }



   .yes {
    background-color: #FF5A5F;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 10px;
    text-align: center;
  }

.yes:hover {
    background-color: red;
    cursor: pointer;
}

.no {
    background-color: rgb(102, 102, 102);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 10px;
    text-align: center;
  }

.no:hover {
    background-color: rgb(145, 145, 145);
    cursor: pointer;
}
