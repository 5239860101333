#modal-content {
  width: 400px;
  max-width: 700px;
  border-radius: 20px;
}

.error-container {
  width: 300px;
  margin-bottom: 10px;
  text-align: center;
  color: red;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.signup-button {
  width: 300px;
  height: 40px;
  font-weight: 900;
  justify-items: center;
  cursor: pointer;
  margin-top: 20px;
  background-color: #FF5A5F;
  color: white;
}

.signup-button:disabled {
  cursor: default;
  color: white;
  background-color: gray;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  border-radius: 40px;
}

.signup-content {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: auto;
  width: 300px;
  margin-bottom: 10px;
}
