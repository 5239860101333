#modal-content {
    width: 400px;
    max-width: 700px;

}

label {
    display: flex;
    flex-direction: column;
}

.LoginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    border-radius: 40px;
}

.LoginUser {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: auto;
    width: 300px;
    margin-bottom: 10px;
}

.LoginButton {
    width: 300px;
    height: 40px;
    font-weight: 900;
    justify-items: center;
    cursor: pointer;
    margin-top: 20px;
    background-color: #FF5A5F;
    color: white;
    border-radius: 10px;
}

.DemoButton {
    justify-items: center;
    text-decoration: underline;
    font-weight: 800;
    cursor: pointer;
}
